.custom-confirm-ui {
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;

    .buttons {
        display: flex;
        column-gap: 20px;
    }
}

.react-responsive-modal-modal {
    max-width: 900px !important;
}

.flex {
    display: flex !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}


.justify-center {
    justify-content: center !important;
}

.items-center {
    align-items: center !important;
}

.items-end {
    align-items: end !important;
}

.flex-col {
    flex-direction: column;
}

.justify-between {
    justify-content: space-between;
}

.gap-0 {
    gap: 2px;
}

.gap-2 {
    gap: 4px;
}

.gap-4 {
    gap: 8px;
}

.gap-5 {
    gap: 10px;
}

.gap-8 {
    gap: 16px;
}

.text-center {
    text-align: center !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.filter {
    i {
        font-size: 20px !important;
    }

    .text {
        font-size: 14px;
        font-weight: 600;
        color: #a1a5b7;
        font-family: Inter, Helvetica, "sans-serif";
    }
}

.text-white {
    color: white !important;
}

.card-styles-schedule {
    background: #e1e3ea;
    padding: 10px;
    border-radius: 8px;
    color: black;
}

.add-medal-list-card {
    background: #e1e3ea;
    padding: 10px 10px;
    padding-right: 20px;
    font-size: 18px;
    color: black;
    border-radius: 8px;
    font-weight: 600;
}